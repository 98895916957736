<template>
    <div>
        <card-header title="Edit Answer" icon="fa-pencil"/>

        <card-body>
            <answer-form :answer="this.answer"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteAnswer"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Answer?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Answer</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import EventForm from "./EventForm";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {client as http} from "../../http_client";
    import AnswerForm from "@/cards/trips/AnswerForm";

    export default {
        components: {AnswerForm, ContainerListItem, SubheaderListItem, CardList, EventForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                answer: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadAnswer: function () {
                this.$emit('loading', true);
                http.get('/api/answers/' + this.props.answerId, {force: true}).then(response => {
                    this.answer = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/answers/' + this.props.answerId, this.answer, {force: true}).then(response => {
                    this.$reloadCard('question', {questionId: this.props.questionId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteAnswer() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/answers/' + this.props.answerId).then(response => {
                        this.$reloadCard('question', {questionId: this.props.questionId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.answerId': function () {
                this.dirty = null;
                this.loadAnswer();
            },
            'answer': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this answer? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadAnswer();
        }
    };
</script>

<style scoped>

</style>
